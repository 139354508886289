<template>
  <div>
    <b-img
      :src="appLogoImage"
      alt="logo"
      height="40"
    />
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageSecond } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss">
.grecaptcha-badge { visibility: hidden !important; }
</style>
